import React from "react"
import { Container, Row } from "react-bootstrap"

const Body = () => {
  return (
    <section className="info-section my-3 my-lg-5">
      <Container>
        <Row className="justify-content-center align-items-center">
          <div className="my-2 my-lg-5">
            <h1 className="info-one-heading h5 fw-bold text-center">
              OUR VISION
            </h1>
            <p className="info-heading">
              Our company provides teleradiology services (i.e. CT, MRI, X-ray)
              to hospitals and diagnostic centres in remote parts of India and
              other parts of world. It is a health care company that uses
              technology for providing teleradiology services to consultants and
              patients. The reports generated are of international standard by
              qualified (as per MCI guidelines), skilled, devoted and hard
              working consultants in Radiodiagnosis. Second opinion can be
              obtained and also offering the weekends support, flexible
              scheduling and excellent reporting quality.
            </p>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default Body
